<template>
  <main class="technical-support">
    <div class="technical-support__header">
      <h4>{{ t('pages.technicalSupport.title') }}</h4>
      <icon-btn
        :size="Size.M"
        @click="goBack"
      >
        <icon
          :type="IconType.Contour"
          :name="IconName.Crosses"
          :size="Size.Xs"
        />
      </icon-btn>
    </div>

    <div class="technical-support__body">
      <form
        class="technical-support-form"
        @submit.prevent="onSubmitWrapper"
      >
        <textfield
          ref="nameRef"
          v-if="!(profile !== null && profile.name)"
          v-model="model.name"
          :rules="[rules.required]"
          :style="TextfieldStyle.Primary"
          :label="t('pages.technicalSupport.nameLabel')"
          :placeholder="t('pages.technicalSupport.nameLabel')"
          autocomplete="name"
        />

        <v-select
          ref="subject"
          :icon-color="activeThemeColor"
          :placeholder="t('pages.technicalSupport.topicsLabel')"
          :error="selectError"
          :options="[
            {
              label: t('pages.technicalSupport.topics.addCity'),
              value: 'add-city'
            },
            {
              label: t('pages.technicalSupport.topics.siteErrors'),
              value: 'site-errors'
            },
            {
              label: t('pages.technicalSupport.topics.paymentErrors'),
              value: 'payment-errors'
            },
            {
              label: t('pages.technicalSupport.topics.others'),
              value: 'others'
            },
          ]"
        />

        <textfield
          ref="messageRef"
          v-model="model.content"
          :style="TextfieldStyle.Primary"
          :label="t('pages.technicalSupport.messageLabel')"
          :placeholder="t('pages.technicalSupport.messageLabel')"
          :rows="4"
          :rules="[rules.required]"
          textarea
          :max-length="1000"
        />

        <textfield
          :style="TextfieldStyle.Primary"
          type="file"
          :placeholder="t('pages.technicalSupport.file')"
          v-model="fileInput"
          @input="onFileUpload"
        >
          <template #right>
            <icon
              :type="IconType.Contour"
              :name="IconName.Attachment"
              :size="Size.M"
              :color="activeThemeColor"
            />
          </template>
        </textfield>

        <textfield
          v-if="profile === null"
          ref="emailRef"
          v-model="model.email"
          :style="TextfieldStyle.Primary"
          :rules="[rules.email]"
          :label="t('pages.technicalSupport.emailLabel')"
          :placeholder="t('pages.technicalSupport.emailLabel')"
        />

        <span class="technical-support-form__note">
          {{ t('pages.technicalSupport.note') }}
          <span
            v-if="profile && profile.email"
          > {{ profile.email }}</span>
        </span>

        <captcha />

        <btn
          w="100%"
          :disabled="isFetching"
          @click.prevent="onSubmit()"
        >
          {{ t('pages.technicalSupport.submit') }}
        </btn>
      </form>
    </div>
  </main>
</template>

<script lang="ts" src="./TechnicalSupport.ts"></script>
<style lang="scss" src="./technicalSupport.scss"></style>
