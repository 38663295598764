<template>
  <div
    class="v-select"
    :tabindex="tabindex"
    @blur="open = false"
  >
    <div
      class="v-select__selected"
      :class="{ 'is-opened': open, 'v-select__selected--error': error }"
      @click="open = !open"
    >
      <span v-if="selected">{{ selected.label }}</span>
      <span
        v-else-if="placeholder"
        class="v-select__placeholder"
      >{{ placeholder }}</span>
      <icon
        class="v-select__chevron"
        :type="IconType.Contour"
        :name="IconName.ChevronDown"
        :size="Size.M"
      />
    </div>
    <div
      class="v-select__options"
      :class="{ 'v-select--is-hide': !open }"
    >
      <div
        v-for="(option, i) of options"
        :key="i"
        :class="{ 'is-selected': selected && selected.value === option.value }"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
        "
      >
        {{ option.label }}
      </div>
    </div>
    <div
      v-if="error"
      class="v-select__error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts" src="./VSelect.ts"></script>
<style lang="scss" src="./v-select.scss"></style>
