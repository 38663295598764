import { defineComponent, nextTick, onBeforeMount, onBeforeUnmount, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { Size } from 'core/styles';
import VSelect from 'components/VSelect';
import { TextfieldStyle } from 'components/Textfield';
import { IconName, IconType } from 'components/Icon';
import { toastEmitter, ToastType } from 'components/Toasts';
import { StoreInjectionKey } from 'store/constants';
import { useStore } from 'vuex';
import { Api } from 'api/api';
import { useApiErrors } from 'composables/apiErrors';
import { ThemeColor } from 'api/users/models/user';
import { useFetching } from 'composables/fetching';
import { Nullable } from 'core/types';
import { useCaptcha } from 'composables/captcha';
import Captcha from 'components/Captcha';
import { RouteNames } from 'router/names';

const TechnicalSupport = defineComponent({
  name: RouteNames.TechnicalSupport,
  components: {
    VSelect,
    Captcha,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore<typeof StoreInjectionKey>();
    const profile = store.getters['users/PROFILE'];
    const { showError } = useApiErrors();
    const success = ref(false);
    const subject = ref();
    let currentTarget: HTMLInputElement | null = null;
    const model = reactive<{
      name: string
      content: string
      email: string
      attachment: File | null
    }>({
      name: '',
      content: '',
      email: '',
      attachment: null,
    });

    const nameRef = ref();
    const emailRef = ref();
    const messageRef = ref();
    const selectError = ref();
    const fileInput = ref();

    const emailRe = /.+@.+\..+/;
    const rules = reactive({
      select: (v: Nullable<string>) => !!v || t('rule.select'),
      required: (v: Nullable<string>) => !!v || t('rule.required'),
      email: (v: string) => emailRe.test(v) || t('rule.required'),
    });

    function validate() {
      if (!subject?.value?.selected?.label) {
        selectError.value = t('rule.required');
      } else {
        selectError.value = '';
      }
      const nameValidation = !nameRef.value || nameRef.value.validate();
      const messageValidation = messageRef.value.validate();
      const emailValidation = !emailRef.value || emailRef.value.validate();

      return !selectError.value && nameValidation && messageValidation && emailValidation;
    }

    const { validateCaptcha, fetch: captchaFetch, getCaptchaRequest, fetchCaptcha, clearCaptcha } = useCaptcha();
    fetchCaptcha();

    const { fetch, isFetching } = useFetching();
    const onSubmitWrapper = () => fetch(onSubmit());

    async function onSubmit() {
      if (!validate()) return;
      if (!validateCaptcha()) return;
      // validate then redirect to signin with props to modify layout
        try {
          if (profile !== null && profile.name) {
            model.name = profile.name;
          }

          const { data } = await captchaFetch(Api.userService.sendIssue(getCaptchaRequest({
            name: model.name,
            subject: subject.value.selected.value,
            content: model.content,
            email: model.email,
            attachment: model.attachment,
          })), false);

          if (data.result === true) {
            success.value = true;
            toastEmitter.emit('toast', { type: ToastType.Success, message: t('pages.technicalSupport.success') });
            model.name = '';
            model.content = '';
            model.email = '';
            model.attachment = null;
            fileInput.value = undefined;
            if (currentTarget) {
              currentTarget.value = '';
            }
            subject.value.selected = null;
            clearCaptcha();
            fetchCaptcha();
          } else if (data.captcha) {
            showError(new Error('Вы не верно ответили на вопрос'));
          } else {
            showError(new Error('Неизвестный ответ от сервера'));
          }
        } catch (error) {
          fetchCaptcha();
          showError(error);
        }
    }

    onBeforeMount(() => {
      document.body.classList.add('auth-layout--body');
    });
    onBeforeUnmount(() => {
      document.body.classList.remove('auth-layout--body');
    });

    function goBack() {
      router.push('/');
    }

    function onFileUpload(e: any) {
      const file = e.target.files[0];
      if (!file) return;
      if (file.size > 10000000) {
        toastEmitter.emit('toast', { type: ToastType.Error, message: t('pages.technicalSupport.fileTooLarge') });
        nextTick(() => {
          fileInput.value = undefined;
        });
        return;
      }
      currentTarget = e.target;
      model.attachment = file;
    }

    const activeThemeColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--theme-color-name') as ThemeColor
        || ThemeColor.Lavender;

    return {
      nameRef,
      emailRef,
      messageRef,
      selectError,
      isFetching,
      profile,
      model,
      subject,
      fileInput,
      rules,

      t,
      goBack,
      onSubmit,
      onFileUpload,
      activeThemeColor,

      Size,
      IconName,
      IconType,
      TextfieldStyle,
      onSubmitWrapper,
    };
  },
});

export default TechnicalSupport;
