import { defineComponent, onMounted, ref } from 'vue';

import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';

const Select = defineComponent({
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    default: {
      type: String,
      required: false,
      default: '',
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    error: {
      type: String,
      default: '',
    },
  },

  emits: ['input'],

  setup(props, { emit }) {
    let selected = props.default
      ? props.default
      : props.options.length > 0
        ? props.options[0]
        : null;

    if (props.placeholder) selected = null;

    onMounted(() => {
      emit('input', selected);
    });

    const open = ref(false);

    return {
      selected,

      IconType,
      IconName,
      Size,
      open,
    };
  },
});

export default Select;
